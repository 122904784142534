import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { ActiveSubscriptionStatus, CreatePaymentSubscription, StripSubscriptionDetails } from '@db-models/active-subscription.model';
import { FeaturesList, SaveSubscriptionTypeFeaturesModel, SubscriptionType } from '@db-models/subscription.model';
import { PaymentIntent } from '@stripe/stripe-js';
import { CryptoService } from '@util-services/crypto.service';
import { HttpClientService } from '@util-services/http-client.service';
import { LocalStorageService } from '@util-services/local-storage.service';
import { LoggerService } from '@util-services/logger.service';
import { Observable, of, switchMap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private httpClientService: HttpClientService,
    private localStorageService: LocalStorageService,
    private cryptoService: CryptoService
  ) { }

  getSubscriptionTypes(): Observable<SubscriptionType[]> {
    return this.httpClientService.get('partners/get_subscription_type_features', {});
  }

  getFeatures(): Observable<FeaturesList[]> {
    return this.httpClientService.get('partners/features', {});
  }

  saveSubscriptionTypeFeatures(body: SaveSubscriptionTypeFeaturesModel): Observable<any> {
    return this.httpClientService.post('partners/save_subscription_type_features', body, {});
  }

  getFeaturesListAndStoreInLocalStorage(): Observable<{ success: boolean }> {
    return this.getFeatures().pipe(
      switchMap(featuresList => {
        const encryptedString = this.cryptoService.encryptValue(JSON.stringify(featuresList));
        this.localStorageService.set(LOCAL_STORAGE_CONSTANT.SUBSCRIPTION_FEATURES_LIST, encryptedString);
        LoggerService.log('[Subscription] Master feature list stored in local storage');
        return of({ success: true });
      }
      ));
  }

  getFeaturesListFromLocalStorage(): FeaturesList[] | null {
    const encrypted = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.SUBSCRIPTION_FEATURES_LIST) as string;
    if (encrypted) {
      const decrypted = this.cryptoService.decryptValue(encrypted) as string;
      return Object.assign([], JSON.parse(decrypted)) as FeaturesList[];
    }
    return null;
  }

  createPaymentIntent(body: StripSubscriptionDetails): Observable<{ paymentIntent: PaymentIntent }> {
    return this.httpClientService.post('stripe/create_payment_intent', body, {});
  }

  createPaymentSubscription(body: StripSubscriptionDetails): Observable<CreatePaymentSubscription> {
    return this.httpClientService.post('stripe/create_subscription', body, {});
  }

  updatePaymentSubscription(body: StripSubscriptionDetails): Observable<ActiveSubscriptionStatus> {
    return this.httpClientService.post('stripe/update_subscription', body, {});
  }

  updatePaymentIntent(body: StripSubscriptionDetails): Observable<{ paymentIntent: PaymentIntent }> {
    return this.httpClientService.post('stripe/update_payment_intent', body, {});
  }

  getActiveStripeSubscription(): Observable<ActiveSubscriptionStatus> {
    return this.httpClientService.get('stripe/get_active_subscription', {});
  }
}
