import { EventEmitter, Injectable, inject } from '@angular/core';
import { WebhookStats } from '@client-models/webhook-stats.model';
import { CalendarAppointmentModel } from '@db-models/calendar-appointment';
import { BookingEventDao } from '@db-models/event-booking.dao';
import { StartSiteDbModel } from '@db-models/startsite-db.model';
import { HttpClientService } from '@util-services/http-client.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StartSiteService {
  private httpClientService = inject(HttpClientService);

  refreshStartSiteDbEvent = new EventEmitter<StartSiteDbModel>();
  refreshBookingAppointments = new EventEmitter<{ workerIds: number[], mode: string }>();
  refreshBookingEvents = new EventEmitter<{ workerIds: number[], mode: string }>();

  isFirstAppointment = false;
  isFirstEvent = false;
  isFirstConference = false;

  getBookingAppointments(body: {
    workers: number[],
    mode: string,
    limit: number,
    offset: number,
  }): Observable<CalendarAppointmentModel[]> {
    return this.httpClientService.post(`appointments/bookings`, body, {});
  }

  getStartSiteStatsByYear(year: number): Observable<StartSiteDbModel> {
    return this.httpClientService.get(`statistics/${year}`, {})
  }

  getStartSiteStatsByYearAndCustomer(customerId: number, year: number): Observable<StartSiteDbModel> {
    const body: { year: number, customer_id: number } = { year, customer_id: customerId };
    return this.httpClientService.post(`statistics/customer`, body, {});
  }

  getWebhookStats(): Observable<WebhookStats> {
    return this.httpClientService.get(`statistics/webhook_requests`, {});
  }

  getBookingEvents(body: {
    workers: number[],
    mode: string,
    limit: number,
    offset: number,
  }): Observable<BookingEventDao[]> {
    return this.httpClientService.post(`customers_slots/bookings`, body, {});
  }
}
